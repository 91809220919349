import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import Forbidden from "../pages/Forbidden";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from "lockr";

interface TemplateGuardProps {
  children: ReactNode;
}

const MessagingFlowsGuard: FC<TemplateGuardProps> = ({ children }) => {
  const permissions = Lockr.get(StorageKeys.Permissions);

  if (!permissions?.textFlowsEnabled) {
    return <Forbidden />;
  }

  return <>{children}</>;
};

MessagingFlowsGuard.propTypes = {
  children: PropTypes.node,
};

export default MessagingFlowsGuard;

import { useSnackbar } from "notistack";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { emailEntryPointService } from "src/services/emailEntryPoints";
import { useStyles } from "src/theme/styles";
import { MenuRoutes } from "src/types/routes";
import { AssignedTypeEnum } from "src/types/shared";
import { EntryPointAssignments, TextEntryPoint, TextEntryPointAssignedTypeToLabelMapping, TypeEnum } from "src/types/textEntryPoints";
import { EmailEntryPointTranslationKeys, HTTPSTATUSCODES } from "src/utils/constants";
import { getEnumValues } from "src/utils/enumHelper";
import { Loader } from "../shared";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from 'yup';
import ChangesFormActions from "../shared/formChanges/ChangesFormActions";
import { FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { CustomTextField } from "../shared/CustomTextField";
import UtilsHelper from "src/utils/utils-helper";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from "lockr";

const AddEditTextEntryPoint: FC = () => {
    const { entryPointId } = useParams();
    const { t } = useTranslation();
    const classes = useStyles();
    const [entryPoint, setEntryPoint] = useState<TextEntryPoint | null>({} as TextEntryPoint);
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();

    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [areAssignmentsLoaded, setAreAssignmentsLoaded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const initialAssignedType = useRef<string | AssignedTypeEnum>('');
    const [allAssignToData, setAllAssignToData] = useState<EntryPointAssignments>({} as EntryPointAssignments);
    const entryPointTypes = useMemo(() => getEnumValues(TypeEnum), []);

    const navigate = useNavigate();
    const route = `${MenuRoutes.Text}/${MenuRoutes.EntryPoints}`;

    const getEntryPoint = async () => {
        if (entryPointId) {
            try {
                const response = await emailEntryPointService.getEmailEntryPointById(entryPointId);

                if (isMountedRef.current && response?.status == HTTPSTATUSCODES.StatusCodeSuccess) {
                    setEntryPoint(response.data);
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        getEntryPoint();
    }, [entryPointId]);

    const getAssignedTypes = () => {
        const permissions = Lockr.get(StorageKeys.Permissions);
        const types = getEnumValues(AssignedTypeEnum);

        if (permissions && !permissions.textFlowsEnabled) {
            return types.splice(0, 1);
        }

        return types;
    };

    const assignedTypes = useMemo(() => getAssignedTypes(), []);

    const getAssignableQueues = async (type: TypeEnum): Promise<any> => {
        try {
            const response = await emailEntryPointService.getAssignToData(type);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                setAllAssignToData(response.data);
                setAreAssignmentsLoaded(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (entryPoint.type !== undefined) {
            getAssignableQueues(entryPoint.type);
        }
    }, [entryPoint]);

    const addEntryPoint = async (newEntryPoint: TextEntryPoint): Promise<void> => {
        setIsSubmitting(true);

        try {
            const response = await emailEntryPointService.createEmailEntryPoint(newEntryPoint);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                enqueueSnackbar(t('TextEntryPoint.CreateSucceeded'), { variant: 'success' });
                setIsSubmitting(false);
                navigate(route);
            }
        } catch (error) {
            if (error?.response?.status === HTTPSTATUSCODES.StatusCodeConflict) {
                enqueueSnackbar(t('TextEntryPoint.EntityWithSamePropertiesExists'), { variant: 'error' });
            } else {
                enqueueSnackbar(t('TextEntryPoint.CreateFailed'), { variant: 'error' });
            }

            setIsSubmitting(false);
            console.error(error);
        }
    };

    const updateEntryPoint = async (updatedEntryPoint: TextEntryPoint): Promise<void> => {
        setIsSubmitting(true);

        try {
            const response = await emailEntryPointService.updateEmailEntryPoint(updatedEntryPoint);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                enqueueSnackbar(t('TextEntryPoint.UpdateSucceeded'), { variant: 'success' });
                setIsSubmitting(false);
                navigate(route);
            }
        } catch (error) {
            if (error?.response?.status === HTTPSTATUSCODES.StatusCodeConflict) {
                enqueueSnackbar(t('TextEntryPoint.EntityWithSamePropertiesExists'), { variant: 'error' });
            } else if (error?.response?.status === HTTPSTATUSCODES.StatusCodeForbidden) {
                enqueueSnackbar(t('TextEntryPoint.EntityDoesNotExist'), { variant: 'error' });
            } else {
                enqueueSnackbar(t('TextEntryPoint.UpdateFailed'), { variant: 'error' });
            }

            setIsSubmitting(false);
            console.error(error);
        }
    };

    const getInitialAssignedType = () => {
        if (!entryPoint.flowId && !entryPoint.queueId) {
            return "" as (string | AssignedTypeEnum);
        }

        if (entryPoint.flowId) {
            return AssignedTypeEnum.Flow;
        }

        return AssignedTypeEnum.Queue;
    }

    const getInitialAssignedTo = () => {
        switch (initialAssignedType.current) {
            case "":
                return "";
            case AssignedTypeEnum.Flow:
                return entryPoint.flowId;
            case AssignedTypeEnum.Queue:
                return entryPoint.queueId;
            default:
                return "";
        }
    }

    useEffect(() => {
        initialAssignedType.current = getInitialAssignedType();
    }, [entryPoint]);

    const handleCancelForm = (handleReset: (e: any) => void, dirty: boolean) => {
        dirty ? handleReset(null) : navigate(route);
    };

    const getSelectMenuItem = (data: any) => {
        return data?.map((type) => (
            <MenuItem key={type.id} value={type.id}
            >
                {type.name}
            </MenuItem>));
    }

    return (
        <>
            <Loader isOpen={!(isDataLoaded || areAssignmentsLoaded || !entryPointId)} isInline={false} />
            <Helmet>
                <title>
                    {entryPointId ? t('TextEntryPoint.EditTextEntryPoint') :
                        t('TextEntryPoint.AddTextEntryPoint')} | ContactCenter4All
                </title>
            </Helmet>
            {(isDataLoaded && areAssignmentsLoaded || !entryPointId) &&
                <Formik
                    initialValues={{
                        id: entryPointId ? entryPoint?.identifier : "",
                        name: entryPointId ? entryPoint?.name : "",
                        email: entryPointId ? entryPoint?.email : "",
                        type: entryPointId ? entryPoint?.type : "" as (string | TypeEnum),
                        assignedType: entryPointId ? getInitialAssignedType() : "" as (string | AssignedTypeEnum),
                        assignedTo: entryPointId ? getInitialAssignedTo() : "",
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            id: Yup
                                .string()
                                .trim()
                                .uuid(t("TextEntryPoint.InvalidId"))
                                .required(t(EmailEntryPointTranslationKeys.Required)),
                            name: Yup
                                .string()
                                .trim()
                                .matches(new RegExp(UtilsHelper.NameRegExp), t('Shared.SpecialCharacter'))
                                .required(t(EmailEntryPointTranslationKeys.Required)),
                            email: Yup
                                .string()
                                .trim()
                                .email(t("TextEntryPoint.InvalidEmailAddress"))
                                .max(255)
                                .required(t(EmailEntryPointTranslationKeys.Required)),
                            type: Yup
                                .string()
                                .required(t(EmailEntryPointTranslationKeys.Required)),
                            assignedType: Yup.string(),
                            assignedTo: Yup
                                .string()
                                .trim()
                                .when(["assignedType"], {
                                    is: AssignedTypeEnum.Queue.toString(),
                                    then: Yup.string().required(),
                                    otherwise: Yup.string().when("assignedType", {
                                        is: AssignedTypeEnum.Flow.toString(),
                                        then: Yup.string().required(),
                                        otherwise: schema => schema,
                                    })
                                }),
                        })}
                    onSubmit={(values) => {
                        const textEntryPoint: TextEntryPoint = {
                            identifier: values.id,
                            name: values.name,
                            email: values.email,
                            queueId: values.assignedType === AssignedTypeEnum.Queue ? values.assignedTo : null,
                            flowId: values.assignedType === AssignedTypeEnum.Flow ? values.assignedTo : null
                        };

                        if (entryPointId) {
                            textEntryPoint.id = entryPointId;
                        }

                        entryPointId ? updateEntryPoint(textEntryPoint) : addEntryPoint(textEntryPoint);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, handleReset, touched, values, dirty }): JSX.Element => (
                        <form onSubmit={handleSubmit}>
                            <ChangesFormActions isValid={true} isSubmitting={isSubmitting} handleReset={() => handleCancelForm(handleReset, dirty)} isSaveAction={false} />
                            <Grid container spacing={2} className={classes.textEntryPoint}>
                                <Grid item xs={5}>
                                    <CustomTextField
                                        size="small"
                                        color="info"
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name}
                                        label={t('TextEntryPoint.Name')}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                        data-testid="name"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <CustomTextField
                                        size="small"
                                        color="info"
                                        error={Boolean(touched.id && errors.id)}
                                        fullWidth
                                        helperText={touched.id && errors.id}
                                        label={t('TextEntryPoint.Id')}
                                        name="id"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.id}
                                        variant="outlined"
                                        data-testid="id"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <CustomTextField
                                        size="small"
                                        color="info"
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label={t('TextEntryPoint.EmailAddress')}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        variant="outlined"
                                        data-testid="email"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="type-select-label"
                                            className={classes.selectComponentLabel}
                                        >
                                            {t('TextEntryPoint.Type')}
                                        </InputLabel>
                                        <Select
                                            value={values.type}
                                            error={Boolean(touched.type && errors.type)}
                                            label={t('TextEntryPoint.Type')}
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    getAssignableQueues(event.target.value as TypeEnum);
                                                }

                                                handleChange(event);
                                            }}
                                            size="small"
                                            name="type"
                                            className={classes.selectComponent}
                                            data-testid="type"
                                        >
                                            <MenuItem value=''>
                                                <ListItemText primary="Select" />
                                            </MenuItem>
                                            {entryPointTypes.map((type: string) => (
                                                <MenuItem key={type} value={type}>
                                                    {TypeEnum[type]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(touched.type && errors.type) && (
                                            <FormHelperText error>
                                                {t(EmailEntryPointTranslationKeys.Required)}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="assigned-type-select-label"
                                            className={classes.selectComponentLabel}
                                        >
                                            {t('TextEntryPoint.AssignedType')}
                                        </InputLabel>
                                        <Select
                                            value={values.assignedType}
                                            error={Boolean(touched.assignedType && errors.assignedType)}
                                            label={t('TextEntryPoint.AssignedType')}
                                            onChange={handleChange}
                                            size="small"
                                            name="assignedType"
                                            className={classes.selectComponent}
                                            data-testid="assignedType"
                                        >
                                            <MenuItem value=''>
                                                <ListItemText primary="Select" />
                                            </MenuItem>
                                            {assignedTypes.map((type: number) => (
                                                <MenuItem key={type} value={type}>
                                                    {t(TextEntryPointAssignedTypeToLabelMapping[type])}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="assigned-to-select-label"
                                            className={classes.selectComponentLabel}
                                        >
                                            {t('TextEntryPoint.AssignedTo')}
                                        </InputLabel>
                                        <Select
                                            value={values.assignedType === "" ? "" : values.assignedTo}
                                            error={Boolean(touched.assignedTo && errors.assignedTo)}
                                            label={t('TextEntryPoint.AssignedTo')}
                                            onChange={handleChange}
                                            size="small"
                                            name="assignedTo"
                                            disabled={values.assignedType === "" || values.type === ""}
                                            className={classes.selectComponent}
                                            data-testid="assignedTo"
                                        >
                                            <MenuItem value=''>
                                                <ListItemText primary="Select" />
                                            </MenuItem>
                                            {values.assignedType === AssignedTypeEnum.Queue ?
                                                getSelectMenuItem(allAssignToData.queues) :
                                                getSelectMenuItem(allAssignToData.flows)}
                                        </Select>
                                        {Boolean(touched.assignedTo && errors.assignedTo) && (
                                            <FormHelperText error>
                                                {t(EmailEntryPointTranslationKeys.Required)}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            }
        </>
    );
};

export default AddEditTextEntryPoint;

import { css } from "@mui/material/styles";
import { css as emotionCss } from "@emotion/css";

export const cssToString = function (logMessage: string, style: TemplateStringsArray)
{
    var serializedStyles = css(style);
    var result = emotionCss(serializedStyles);
    return result;
}

export const getStylesAsStringsNonThemed = function (getStylesAsObjects: any)
{
    let obj = getStylesAsObjects;
    if(typeof getStylesAsObjects === 'function') {
         obj = getStylesAsObjects();
    }
    var objEntries = Object.entries(obj);
    var mappedEntries = objEntries.map(
        ([key, value]) => [key, cssToString(key, value as any)]
    );
    var result = Object.fromEntries(mappedEntries);
    return result;
 }
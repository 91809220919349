export enum ResponseResult
{
    VALIDATIONERROR = 0,
    EXCEPTION= 1,
    SUCCESS = 2
}

export interface ApiResponse<T = any> {
    data?: T,
    result: ResponseResult
}

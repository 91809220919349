import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { getCurrentUserRoles } from 'src/utils/jwt-helper';
import Forbidden from 'src/pages/Forbidden';

interface RoleGuardProps {
  children: ReactNode;
  roles: string[];
  enforceAllRoles?: boolean;
}

const RoleGuard: FC<RoleGuardProps> = (props) => {
  const { children, roles, enforceAllRoles } = props;

  const currentUserRoles = getCurrentUserRoles();
  if ((enforceAllRoles && !roles.every(r => currentUserRoles.includes(r))) ||
    !currentUserRoles.some(r => roles.includes(r))) {
    return <Forbidden />;
  }

  return <>{children}</>;
};

RoleGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array,
  enforceAllRoles: PropTypes.bool
};

export default RoleGuard;
export enum MenuRoutes {
    Authentication = '/authentication',
    Login = 'login',
    LoginMultitenant = 'login-multitenant',
    LoginUnguarded = 'login-unguarded',
    Dashboard = '/dashboard',
    Calls = '/calls',
    Queues = 'queues',
    NewQueue = 'queues/new',
    EditQueue = 'queues/:queueId',
    Flows = 'flows',
    NewFlow = 'flows/new',
    EditFlow = 'flows/:flowId',
    DesignFlow = 'flows/design/:flowId',
    NewAppInstance = 'appinstances/new',
    EntryPoints = 'entrypoints',
    NewEntryPoint = 'entrypoints/new',
    EditEntryPoint = 'entrypoints/:entryPointId',
    Generic = '/generic',
    Users = '/users',
    EditUser='/users/:userId',
    EditMember='/users/:userId/:queueType/:queueId',
    GeneralSettings = '/generalsettings',
    Contacts = '/contacts',
    Schedules = '/schedules',
    NewSchedule = '/schedules/new',
    EditSchedule = '/schedules/:scheduleId',
    Events = '/events',
    NewEvent = '/events/new',
    EdiEvent = '/events/:eventId',
    MediaFiles = '/mediafiles',
    NewMediaFile = '/mediafiles/new',
    EditMediaFile = '/mediafiles/:mediafileId',
    EditDynamicMediaFile = '/mediafiles/:mediafileId/:dynamicId',
    WrapUp = '/wrapup',
    NewWrapUp = '/wrapup/new',
    EditWrapUp = '/wrapup/:wrapUpCollectionId',
    Account = 'account',
    Tenants = '/tenants',
    NewTenant = '/tenants/new',
    EditTenant = '/tenants/:tenantId',
    Forbidden = '/403',
    Skills = '/skills',
    NewSkill = '/skills/new',
    EditSkill = '/skills/:skillId',
    Text='/text',
    Templates="/templates",
    NewTemplate="/templates/new",
    EditTemplate="/templates/:templateId",
};
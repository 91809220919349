import { ApiGeneralSettings } from ".";
import { InvokeServiceArgs } from "../interfaces";
import { serviceCall } from "../service-call";
import { AxiosResponse } from 'axios';
import { HttpMethods } from "../httpMethods";
import { Controllers, GeneralSettingsRoutes } from "../../utils/constants";
import { Webhook } from "src/types/webhook";
import { Reason } from "src/types/reason";
import { Key } from "src/types/key";

export class GeneralSettingsService implements ApiGeneralSettings {
    
    async getwebhooks(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.GeneralSettings, GeneralSettingsRoutes.Webhooks);
    }

    async updateWebhooks(webhooks: Webhook[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.GeneralSettings, GeneralSettingsRoutes.UpdateWebhooks, null, webhooks);
    }

    async getReasons(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Reason, GeneralSettingsRoutes.Reasons);
    }

    async updateReasons(webhooks: Reason[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Reason, GeneralSettingsRoutes.UpdateReasons, null, webhooks);
    }

    getKeys(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Key, GeneralSettingsRoutes.Keys);
    }

    updateKeys(keys: Key[]) {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Key, GeneralSettingsRoutes.Keys, null, keys);
    }

    async resyncTenant(tenantId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.TenantManager, GeneralSettingsRoutes.Resync, `?tenantId=${tenantId}`);
    }

    async resyncAllTenants(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.TenantManager, GeneralSettingsRoutes.ResyncAll, null);
    }

    async getPermissions(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.GeneralSettings, GeneralSettingsRoutes.Permissions);
    }

    async checkIfSkillsAreEnabled(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.GeneralSettings, GeneralSettingsRoutes.SkillsEnabled);
    }

    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };

        return serviceCall.invokeService(args);
    }
}
import { ApiAuthService } from ".";
import { InvokeServiceArgs } from "../interfaces";
import { serviceCall } from "../service-call";
import { AxiosResponse } from 'axios';
import { HttpMethods } from "../httpMethods";
import { Controllers, AuthApiRoutes } from "../../utils/constants";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from 'lockr';
import { LoginRequest } from "src/types/user";

export class AuthService implements ApiAuthService {
    async verifyUser(data: LoginRequest): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.User, AuthApiRoutes.VerifyUser, null, data);
    }

    async signout(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.User, AuthApiRoutes.SignOut, null);
    }

    async getTimezone(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, AuthApiRoutes.Timezone);
    }

    async getDeployments(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Deployments, AuthApiRoutes.Deployments, `/${localStorage.getItem(StorageKeys.AzureTenantId)}/${Lockr.get(StorageKeys.LocalAccountId)}`);
    }

    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };

        return serviceCall.invokeService(args);
    }
}
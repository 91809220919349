import { AxiosResponse } from 'axios';
import { SkillService } from './skillsService';
import { Skill } from 'src/types/skill';

export interface ApiSkills {
    getSkills(urlParams: string): Promise<AxiosResponse>;
    deleteSkills(data: string[]): Promise<AxiosResponse>;
    getSkillById(skillId: string): Promise<AxiosResponse>;
    createSkill(skill: Skill): Promise<AxiosResponse>;
    updateSkill(skill: Skill): Promise<AxiosResponse>;
}

export const skillService = new SkillService();
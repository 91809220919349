import { FC } from "react";
import { useTranslation } from "react-i18next";
import SharedGridPage from "./SharedGridPage";
import { EditUser } from "src/components/users";

const ManageUser: FC = () => {
    const { t } = useTranslation();

    return (
        <SharedGridPage
            title={t('Users.EditUser')}
            subComponent={<EditUser />}
        />
    );
};

export default ManageUser;
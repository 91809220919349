import React, { FC } from 'react';
import { Box, Button, } from '@mui/material';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { useStyles } from "../../../theme/styles";
import { ChangesFormActionsProps } from './changesFormActionsProps';

const ChangesFormActions: FC<ChangesFormActionsProps> = (props) => {
	const { isValid, isSubmitting, handleReset, hideFormButtons, isSaveAction,handleSubmit } = props;

	const { t } = typeof jest !== 'undefined' ? { t: s => s } : useTranslation();
	const classes = useStyles();

	const handleCancel = () => {
		handleReset();
		if (hideFormButtons) {
			hideFormButtons();
		}
	}

	const handleFormSubmit=(e:React.FormEvent)=>{
		if(handleSubmit){
			handleSubmit();
			e.preventDefault();
		}
	}

	return (
		 <Box
			sx={{
				display: isValid ? 'flex' : 'none',
				justifyContent: 'flex-end',
				p: 1,
			}}
		>
			<Button
				disabled={isSubmitting}
				variant="contained"
				type="button"
				className={classes.cancelButton}
				onClick={handleCancel}
			>
				{t('Shared.Cancel')}
			</Button>
			{isSaveAction &&
				<Button
					disabled={isSubmitting}
					type="submit"
					variant="contained"
					className={classes.saveButton}
				>
					{t('Shared.Save')}
				</Button>
			}
			{!isSaveAction &&
				<Button
					disabled={isSubmitting}
					variant="contained"
					type="submit"
					className={classes.saveButton}
					onClick={(e)=>handleFormSubmit(e)}
				>
					{t('Shared.Save')}
				</Button>
			}
		</Box>
	);
};

ChangesFormActions.propTypes = {
	// @ts-ignore
	isValid: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	handleReset: PropTypes.func,
	hideFormButtons: PropTypes.func,
	isSaveAction: PropTypes.bool
};

export default ChangesFormActions;

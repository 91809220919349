import { FC } from 'react';
import type { TooltipTextAreaProps } from '../../src/types/tooltipTextArea';
import { Tooltip } from '@mui/material';
import "src/theme/css/pagination.css";
import { getStylesAsStringsNonThemed } from 'src/utils/stylesHelper';

const styles = {
    customTooltip: {
        marginTop: '0px!important',
        maxWidth: "500px!important"
    },
    tooltipTextArea: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
};

const TooltipTextArea: FC<TooltipTextAreaProps> = (props) => {
    const { text } = props;
    const classes = getStylesAsStringsNonThemed(styles);
    return (
        <Tooltip
            key={Math.random()}
            classes={{
                tooltip: classes.customTooltip,
            }}
            title={text}
            placement="bottom-start"
            enterDelay={1000}>
            <div className={classes.tooltipTextArea}>{text}</div>
        </Tooltip>
    );
};

export default TooltipTextArea;
//seems to be from https://github.com/jmlweb/isMounted/blob/master/index.es.js
import { useEffect, useRef } from 'react';
import type { MutableRefObject } from 'react';

const useIsMountedRef = (): MutableRefObject<boolean> => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false
    };
  }, []);

  return isMounted;
};

export default useIsMountedRef;

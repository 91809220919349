import { BotApplicationInstance } from '../types/botApplicationInstance';
import { AssignationStatus } from '../types/enums';

const itemHeight = 48;
const itemPaddingTop = 8;
export const botAppSelectMenuProps = {
    PaperProps: {
        style: {
            maxHeight: itemHeight * 4.5 + itemPaddingTop,
            width: 250,
        },
    },
    autoFocus: false,
};

export const mapBotApplicationInstances = (data: any, botInstances: any[] = []) => {
    const botApplicationInstances: BotApplicationInstance[] = [];

    data.forEach(element => {
        botApplicationInstances.push({
            id: element.id,
            phoneNumber: element.phoneNumber,
            flowId: element.flowId,
            queueId: element.queueId,
            creatorId: element.creatorId,
            assignedMediaName: element.assignedMediaName,
            userPrincipalName: element.userPrincipalName,
            displayName: element.displayName
        } as BotApplicationInstance)
    });

    return botApplicationInstances.sort(
        function (a, b) {
            const aName = a.phoneNumber || a.userPrincipalName || a.displayName;
            const bName = b.phoneNumber || b.userPrincipalName || b.displayName;
            if (botInstances.includes(aName)) {
                return 1;
            }
            if (botInstances.includes(bName)) {
                return -1;
            }
            if (!a.flowId && !a.queueId) {
                return -1;
            }
            if (!b.flowId && !b.queueId) {
                return 1;
            }
            if (!a.phoneNumber || !b.phoneNumber) {
                return -1;
            }
            return a.phoneNumber.localeCompare(b.phoneNumber);
        });
};

export const getBotApplicationInstanceIds = (values: string[], allBotApplicationInstances: BotApplicationInstance[]) => {
    const idList: string[] = [];
    values.forEach(element => {
        idList.push(getBotApplicationInstanceId(element, allBotApplicationInstances));
    });
    return idList;
};

const getBotApplicationInstanceId = (value: string, allBotApplicationInstances: BotApplicationInstance[]) => {
    return allBotApplicationInstances.find(x => x.phoneNumber === value || x.userPrincipalName === value || x.displayName === value).id;
}

export const isDisabled = (alreadyAssignedPhoneNumbers: any, botApplicationInstance: BotApplicationInstance) => {
    const botAppName = botApplicationInstance.phoneNumber || botApplicationInstance.userPrincipalName || botApplicationInstance.displayName;
    return (botApplicationInstance.flowId === null && botApplicationInstance.queueId === null) ||
        alreadyAssignedPhoneNumbers.indexOf(botAppName) > -1 ? false : true;
}

export const getAssignMessage = (status: AssignationStatus) => {
    switch (status) {
        case AssignationStatus.AssignedToFlow:
            return 'Flow.AssignedToFlow';
        case AssignationStatus.AssignedToQueue:
            return 'Flow.AssignedToQueue';
        default:
            return '';
    }
}

export const getAssignationStatus = (botApplicationInstance: BotApplicationInstance) => {
    let assignationStatus = AssignationStatus.Unassigned;
    if (botApplicationInstance.flowId) {
        assignationStatus = AssignationStatus.AssignedToFlow;
    }

    if (botApplicationInstance.queueId) {
        assignationStatus = AssignationStatus.AssignedToQueue
    }

    const assignationStatusMessage = getAssignMessage(assignationStatus);

    return { assignationStatus: assignationStatus, assignationStatusMessage: assignationStatusMessage };
}

export const getAppInstanceText = (bottAppName: string, assignMessage: string, mediaName: string) => {
    return `${bottAppName} (${assignMessage}: ${mediaName})`;
}

export const getListItemText = (assignationStatus: AssignationStatus, assignationStatusMessage: string, botApplicationInstance: BotApplicationInstance) => {
    const bottAppName = botApplicationInstance.phoneNumber || botApplicationInstance.userPrincipalName || botApplicationInstance.displayName;
    return assignationStatus === AssignationStatus.Unassigned ?
        bottAppName :
        getAppInstanceText(bottAppName, assignationStatusMessage, botApplicationInstance.assignedMediaName)
}



export enum SortOrder {
    Asc = "asc",
    Desc = "desc"
}

export enum ThemeColor {
    Default = "default",
    Primary = "primary",
    Secondary = "secondary",
    Error = "error",
    Info = "info",
    Success = "success",
    Warning = "warning"
}

export enum ThemeAlign {
    Left = "left",
    Inherit = "inherit",
    Right = "right",
    Center = "center",
    Justify = "justify"
}

export enum ThemeSize {
    Small = "small",
    Medium = "medium"
}

export enum LabelPlacement {
    Top = "top",
    Bottom = "bottom",
    Start = "start",
    End = "end"
}

export enum AssignationStatus {
    Unassigned = 0,
    AssignedToQueue = 1,
    AssignedToFlow = 2
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StorageKeys } from 'src/utils/storageKeys';
import { apiConfig } from '../config';
import { ResponseResult } from '../types/apiResponse';
import { Controllers, AuthApiRoutes } from '../utils/constants';
import Lockr from 'lockr';
import { jwtDecode } from 'jwt-decode';

const refreshTokenCall = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${apiConfig.fullUrl}/${Controllers.RefreshToken}/${AuthApiRoutes.RefreshToken}`,
            {
                headers: { '__tenant': Lockr.get(StorageKeys.TenantId) || '' },
                withCredentials: true // Must be set to true to include HttpOnly cookies
            }
        ).then(rs => {
            if (rs.data.result === ResponseResult.SUCCESS) {
                // Set new access token to local storage
                Lockr.set(StorageKeys.AccessToken, rs.data.data);
                resolve(rs.data.data);
            }
            else {
                // SHOULD RELOGIN
                logoutFromRefresh();
                reject(new Error(rs.data.data));
            }
        }).catch(err => {
            // SHOULD RELOGIN
            logoutFromRefresh();
            reject(err);
        });
    });
}

const logoutFromRefresh = (): void => {
    // REIMPLEMENT IN OTHER WAY
    Lockr.set(StorageKeys.AccessToken, null);
    window.localStorage.clear();
    window.location.reload();
}

const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    return new Promise<AxiosRequestConfig>((resolve, _reject) => {
        config.headers['__tenant'] = Lockr.get(StorageKeys.TenantId) || '';
        config.withCredentials = true; // IMPORTANT
        const token = Lockr.get<string>(StorageKeys.AccessToken);
        let requestedNewToken = false;
        if (token != null && token.length > 0) {
            const decodedToken = jwtDecode(token);
            if (decodedToken != null && (decodedToken as any).exp != null) {
                // Check expiration
                const currentDateTime = Math.floor(new Date().getTime() / 1000); // milliseconds to seconds
                const tokenExpiryDateTime = (decodedToken as any).exp; // seconds
                // Check if token is expired or will expire in the next 20 seconds
                if (tokenExpiryDateTime - currentDateTime < 20) {
                    // Request a new access token based on the refresh token
                    requestedNewToken = true;
                    refreshTokenCall().then(newToken => {
                        config.headers['Authorization'] = `Bearer ${newToken}`;
                        resolve(config);
                    }).catch(_err => {
                        resolve(config);
                    });
                }
                else {
                    // Use existing access token
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
            }
        }
        if (!requestedNewToken) {
            resolve(config);
        }
    });
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
};

const onResponseError = async (error: AxiosError) => {
    if (error == null) {
        return Promise.reject(null);
    }
    if (error.response == null) {
        return Promise.reject(error);
    }
    // Access Token was expired or invalid. This is a fallback to onRequest interceptor
    if (error.response.status === 401 && error.response.data === 'Token validation failed.') {
        try {
            // Request a new access token based on the refresh token
            const newToken = await refreshTokenCall();
            // Update authorization bearer token
            error.config.headers['Authorization'] = `Bearer ${newToken}`;
            // Retry the original request
            return axios.request(error.config);
        }
        catch (_error) {
            logoutFromRefresh();
            return Promise.reject(_error);
        }
    }
    return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

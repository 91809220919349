import { TextField } from '@mui/material';
import {styled} from '@mui/material/styles';

export const CustomTextFieldStyle = {
    root: {
        "& label": {
            color: "#00bcd4"
        },
        "& fieldset": {
            borderColor: "#00bcd4"
        }
    }
};

export const CustomTextField = styled(TextField)(CustomTextFieldStyle.root);
export enum StorageKeys {
    Timezone = 'Abp.Timing.TimeZone',
    TenantId = 'tenantId',
    TenantName = 'tenantName',
    LocalAccountId = 'localAccountId',
    Email = 'email',
    Name = 'name',
    AccessToken = 'accessToken',
    AuthenticationResult = 'authenticationResult',
    IdToken = 'idToken',
    Settings = 'settings',
    Language = 'language',
    LoginTenant = 'loginTenant',
    AzureTenantId = 'azureTenantId',
    DeploymentTenantKey = 'td',
    DeploymentTenantId = 'tdid',
    ParentTenantId = 'ptid',
    Permissions = 'permissions'
}

import type { FC } from 'react';
import {
    Checkbox,
    FormControlLabel,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import { SortOrder, ThemeColor, ThemeAlign, ThemeSize, LabelPlacement } from '../../types/enums';
import { useStyles } from "../../theme/styles";
import { Info } from "@mui/icons-material";

interface GridHeaderProps {
    headers: HeaderElement[];
}

interface HeaderElement {
    key: string;
    columnId: string;
    columnName: string;
    orderBy?: string;
    order?: SortOrder;
    onSortChanged?: any;
    isCheckbox?: boolean;
    isSortable?: boolean;
    padding?: string;
    isChecked?: boolean;
    isIndeterminate?: boolean;
    changeCallback?: any;
    align?: ThemeAlign;
    size?: ThemeSize,
    color?: ThemeColor;
    testId?: string;
    sx?: any;
    isEmpty?: boolean;
    labelPlacement?: LabelPlacement;
    style?: any;
    tooltipText?: string;
}

const GridHeader: FC<GridHeaderProps> = (props) => {
    const { headers } = props;
    const classes = useStyles();
    const createSortHandler =
        (element: HeaderElement) => (event: React.MouseEvent<unknown>) => {
            element.onSortChanged(element.columnId);
        };

    const changeHandler = (element: HeaderElement) => (event: React.ChangeEvent<HTMLInputElement>) => {
        element.changeCallback(event);
    }

    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((element) => {
                        if (!element) {
                            return;
                        }
                        const direction = element.orderBy === element.columnId ? element.order : SortOrder.Asc;

                        const result = <TableCell key={element.key}
                            padding={element.isCheckbox ? "checkbox" : null}
                            align={element.align || ThemeAlign.Inherit}
                            size={element.size || ThemeSize.Medium}
                            sx={element.sx}
                            sortDirection={element.isSortable && element.orderBy === element.columnId ? element.order : false}
                            style={element.style}
                        >
                            {
                                element.isCheckbox &&
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={element.isChecked}
                                        color={element.color || ThemeColor.Primary}
                                        indeterminate={element.isIndeterminate}
                                        onChange={changeHandler(element)}
                                    />}
                                    label={element.tooltipText ? 
                                        <div className={classes.flex}>
                                            <div>{element.columnName}</div>
                                            <Tooltip
                                            title={element.tooltipText}
                                            >
                                                <Info color="primary" fontSize="small" className={classes.operatorTooltipMargin}/>
                                            </Tooltip>
                                        </div> : 
                                        <div>{element.columnName}</div>}
                                    labelPlacement={element.labelPlacement || LabelPlacement.End}
                                    className={(!element.labelPlacement || element.labelPlacement === LabelPlacement.End) ? classes.defaultTableHeaderLabel : classes.tableHeaderWithCheckboxAndLabelOnTop}
                                />
                            }

                            {element.isSortable ?
                                <TableSortLabel
                                    active={element.orderBy === element.columnId}
                                    direction={direction}
                                    data-testid={element.testId}
                                    onClick={createSortHandler(element)}
                                >
                                    {element.columnName}
                                </TableSortLabel> :
                                !element.labelPlacement && <span>{element.columnName}</span>
                            }
                            {element.isEmpty && <></>}
                        </TableCell>;
                        return result;
                    })
                }
            </TableRow>
        </TableHead>
    );
};

export default GridHeader;

import type { FC } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  experimentalStyled
} from "@mui/material";
import type { AppBarProps } from "@mui/material";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import useAuth from "src/hooks/useAuth";
import TimezoneComponent from "./TimezoneComponent";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          sx={{ display: { lg: "none", xs: "block" } }}
          onClick={onSidebarMobileOpen}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <TimezoneComponent/>
        <LanguagePopover />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <Typography color="textSecondary" variant="h6">
            {user.name}
          </Typography>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;

import { Button, Grid, Typography } from "@mui/material";
import { FieldArray } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/theme/styles";
import { SkillsSectionProps, UserSkill } from "src/types/skill";
import UserSkillRow from "./UserSkillRow";
import { addUserSkill } from "src/utils/skillHelper";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const SkillsSection: FC<SkillsSectionProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { values, handleChange, handleBlur, assignableOptions, errors, 
        touched, assignedOptions, skillId, userId, optionsTitle, optionName } = props;

    return (
        <>
            <Grid item xs={5} className={classes.rowField}>
                <Typography
                    color="textPrimary"
                    variant="h6"
                >
                    {optionsTitle}
                </Typography>
            </Grid>
            <Grid item xs={5} className={classes.rowField}>
                <Typography
                    color="textPrimary"
                    variant="h6"
                >
                    {t('Skills.SkillLevel')}
                </Typography>
            </Grid>
            <FieldArray name="userSkills" render={arrayHelpers => (
                <>
                    {values.userSkills && (
                        <Grid item xs={12}>
                            {
                                values.userSkills.map((item: UserSkill, index) => (
                                    <UserSkillRow
                                        key={item.rowId}
                                        userSkill={item}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onDelete={() => arrayHelpers.remove(index)}
                                        index={index}
                                        assignableOptions={assignableOptions}
                                        errors={errors.userSkills ? errors.userSkills[index] : null}
                                        touched={touched.userSkills ? touched.userSkills[index] : null}
                                        assignedOptions={assignedOptions}
                                        optionName={optionName}
                                    />
                                ))
                            }
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            startIcon={<AddCircleIcon />}
                            variant="contained"
                            className={classes.skillAddButton}
                            onClick={() => addUserSkill(arrayHelpers, skillId, userId)}
                        >
                            {t('Shared.Add')}
                        </Button>
                    </Grid>
                </>
            )} />
        </>
    );
};

export default SkillsSection;

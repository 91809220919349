import { LoginRequest } from "src/types/user";
import { AuthService } from "./authService";
import { AxiosResponse } from 'axios';

export interface ApiAuthService {
    verifyUser(data: LoginRequest): Promise<AxiosResponse>;
    getTimezone(): Promise<AxiosResponse>;
    signout(): Promise<AxiosResponse>;
}

export const authService = new AuthService();
import { Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from 'lockr';
import FormattedDate from "../shared/FormattedDate";

const TimezoneComponent: FC = () => {
    const tenantName = Lockr.get<string>(StorageKeys.TenantName);
    const [timeZone, setTimeZone] = useState<string>("");
    const [date, setDate] = useState<string>(new Date().toUTCString());

    const refreshTime = () => {
        setDate(new Date().toUTCString());
        setTimeZone(Lockr.get<string>(StorageKeys.Timezone));
    }

    useEffect(() => {
        const timerId = setInterval(refreshTime, 10000);
        const timeoutId = setTimeout(() => setTimeZone(Lockr.get<string>(StorageKeys.Timezone)), 500);

        return function cleanup() {
            clearInterval(timerId);
            clearTimeout(timeoutId);
        };
    }, [])

    return (
        <>
            <Typography color="textSecondary" variant="body1" sx={{ marginRight: '16px' }}>
                {tenantName} - <FormattedDate dateValue={date} /> {`(${timeZone})`}
            </Typography>
        </>
    );
}

export default TimezoneComponent;
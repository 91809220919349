import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import English from 'src/localization/en-en.json';
import Dutch from 'src/localization/nl-nl.json';
import French from 'src/localization/fr-fr.json';
import German from 'src/localization/de-de.json';
import Italian from 'src/localization/it-it.json';
import Spanish from 'src/localization/es-es.json';

const resources = {
  en: {
    translation: English
  },
  nl: {
    translation: Dutch
  },
  de: {
    translation: German
  },
  fr: {
    translation: French
  },
  es: {
    translation: Spanish
  },
  it: {
    translation: Italian
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

const customColors = {
    darkText: '#202020',
    saveButtonColor: '#37C270',
    saveButtonHoverColor: '#169F4E',
    cancelButtonColor: '#EEF2F2',
    cancelButtonHoverColor: '#C0D5DC',
    cancelButtonTextColor: '#5A5A5A',
    cancelButtonTextHoverColor: '#000000',
    primaryColor: '#598EA2',
    errorRed: '#f44336'
}

export default customColors;

import React from "react";

export default class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
    }

    componentDidCatch(error: any, errorInfo: any) {
        const message = error.message;

        if (!/ChunkLoadError:.*failed./i.test(message) &&
            !/Loading.*chunk.*failed./i.test(message)) {
            return;
        }

        console.log(error)
        window.location.reload();
    }

    render() {
        return this.props.children;
    }
}

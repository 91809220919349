import { FieldArrayRenderProps } from "formik";
import { UserSkill } from "src/types/skill";
import { SkillsDefaults } from "./constants";

export const setRowIdForUserSkills = (userSkills: UserSkill[]) => {
    userSkills.forEach((x: UserSkill) => {
        x.rowId = x.id;
    })
};

export const addUserSkill = (arrayHelpers: FieldArrayRenderProps, skillId: string, userId: string) => {
    const newUserSkill: UserSkill = {
        skillId: skillId,
        userId: userId,
        skillLevel: SkillsDefaults.SkillLevel,
        rowId: `${Math.random()}`
    };
    arrayHelpers.push(newUserSkill);
};
import type { FC } from 'react';
import {
    Checkbox,
    TableCell,
    TableRow,
} from '@mui/material';
import { ThemeColor, ThemeAlign, ThemeSize } from '../../types/enums';

interface GridBodyProps {
    elements: BodyCellElement[];
    rowDetails?: BodyRowElement;
}

interface BodyCellElement {
    key: string;
    id?: string;
    isCheckbox?: boolean;
    isTextElement?: boolean;
    isTouched?: boolean;
    isChecked?: boolean;
    changeCallback?: any;
    align?: ThemeAlign;
    size?: ThemeSize,
    color?: ThemeColor;
    testId?: string;
    rowTestId?: string;
    sx?: any;
    isDisabled?: boolean;
    textContent?: string;
    checkBoxValue?: boolean;
    isCustomTemplate?: boolean;
    customTemplate?: any;
}
interface BodyRowElement {
    rowKey: string;
    isSelected?: boolean;
    rowTestId?: string;
    canHover?: boolean;
}

const GridBody: FC<GridBodyProps> = (props) => {
    const { elements, rowDetails } = props;
    const changeHandler =
        (element: BodyCellElement) => (event: any) => {
            element.changeCallback(event, element.id);
        };

    return (
        <TableRow
            data-testid={rowDetails.rowTestId}
            hover={rowDetails.canHover}
            key={rowDetails.rowKey}
            selected={rowDetails.isSelected}
        >
            {
                elements.map((element) => {
                    if (!element) {
                        return;
                    }

                    return (
                        <TableCell key={element.key}
                            padding={element.isCheckbox ? "checkbox" : null}
                            align={element.align || ThemeAlign.Inherit}
                            size={element.size || ThemeSize.Medium}
                            sx={element.sx}
                        >
                            {
                                element.isCheckbox && <Checkbox
                                    checked={element.isChecked}
                                    color={element.color || ThemeColor.Primary}
                                    onChange={changeHandler(element)}
                                    value={element.checkBoxValue}
                                    data-testid={element.testId}
                                    disabled={element.isDisabled}
                                />
                            }

                            {element.isTextElement &&
                                <>
                                    {element.textContent}
                                </>
                            }
                            {element.isCustomTemplate && <>
                                {element.customTemplate}
                            </>}
                        </TableCell>
                    );
                })
            }
        </TableRow>
    )
};
export default GridBody;

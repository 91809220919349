import { useEffect } from 'react';
import type { FC } from 'react';
import {
    Box,
    Grid,
    Typography,
    Tooltip
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SharedGridPageProps } from '../types/sharedGridPage';
import InfoIcon from '@mui/icons-material/Info';

const SharedGridPage: FC<SharedGridPageProps> = (props) => {

    return (
        <>
            <Helmet>
                <title>{props.title} | ContactCenter4All</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.paper', minHeight: '100%', py: 8 }}>
                <Box sx={{ mx: 5 }}>
                    <Grid container spacing={3}>
                        <Grid
                            alignItems="center"
                            container
                            spacing={2}
                            item
                            xs={12}
                        >
                            <Grid item sx={{ paddingBottom: "10px" }}>
                                <Typography color="textPrimary" variant="h5">
                                    {props.title}
                                </Typography>
                            </Grid>
                            {props.info &&
                                <Grid item>
                                    <Tooltip title={props.info}>
                                        <InfoIcon color='primary' />
                                    </Tooltip>
                                </Grid>
                            }
                            <Grid item width={1}>
                                <Box sx={{ mt: 3 }}>
                                    {props.subComponent}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default SharedGridPage;

import { FC, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import ReactPlayer from "react-player";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { AnnouncementDto, AnnouncementType } from '../../types/announcement';
import { MediaFileExtension } from '../../utils/constants';
import { mediaFiles } from "../../config";
import { StorageKeys } from '../../utils/storageKeys';
import Lockr from 'lockr';

interface AnnouncementPlayDialogProps {
    handleClose: any;
    open: boolean;
    announcement: AnnouncementDto;
}

const AnnouncementPlayDialog: FC<AnnouncementPlayDialogProps> = (props) => {
    const { announcement, open, handleClose } = props;
    const [announcementUrlToPlay, setAnnouncementUrlToPlay] = useState<string>('');

    useEffect(() => {
        setAnnouncementUrlToPlay(getAnnouncementPlayUrl(announcement));
    }, [announcement]);

    const getAnnouncementPlayUrl = (announcementDto: AnnouncementDto) => {
        const tenantId = Lockr.get(StorageKeys.TenantId) || '';
        const announcementFolder = announcementDto?.announcementType != null ?
            AnnouncementType[announcementDto?.announcementType.toString()]?.toLowerCase() :
            AnnouncementType[AnnouncementType.NoType.toString()]?.toLowerCase();
        return `${mediaFiles.mediaBlobContainerUrl}/${tenantId}/${announcementFolder}/` +
            `${announcementDto?.id}.${MediaFileExtension.Wav}?r=${(Math.random() + 1).toString(36).substring(7)}`;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ReactPlayer
                playing={true}
                controls={true}
                height="50px"
                width="300px"
                style={{ margin: '5px' }}
                playIcon={
                    <PlayCircleOutlineIcon />
                }
                url={announcementUrlToPlay}
            />
        </Dialog>
    );
};

export default AnnouncementPlayDialog;


import { Configuration, PopupRequest } from "@azure/msal-browser";
import { authMsalConfig } from "./config";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: authMsalConfig.clientId,
        authority: authMsalConfig.aadAuthority,
        redirectUri: "/blank.html",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: []
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
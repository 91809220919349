import { AxiosResponse } from "axios";
import { ApiSkills } from ".";
import { HttpMethods } from "../httpMethods";
import { InvokeServiceArgs } from "../interfaces";
import { serviceCall } from "../service-call";
import { Controllers, GeneralRoutes, SkillsApiRoutes } from "src/utils/constants";
import { Skill } from "src/types/skill";

export class SkillService implements ApiSkills {
    async getSkills(urlParams: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Skill, SkillsApiRoutes.Skills, urlParams);
    }

    async deleteSkills(data: string[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Skill, GeneralRoutes.Delete, null, data);
    }

    async getSkillById(skillId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Skill, '', `${skillId}`);
    }

    async createSkill(skill: Skill): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Skill, GeneralRoutes.Create, null, skill);
    }

    async updateSkill(skill: Skill): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.Skill, GeneralRoutes.Update, null, skill);
    }

    async getAllSkills(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.Skill, SkillsApiRoutes.AllSkills);
    }

    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };

        return serviceCall.invokeService(args);
    }
}
export interface AnnouncementDto {
    id: string;
    announcementType: AnnouncementType,
    text: string,
    language: string,
    voice: string
    fileName?: string;
    isDefault: boolean,
    dynamicCollection?: PagedDynamicAnnouncements;
}



export interface PagedDynamicAnnouncements {
    items?: DynamicAnnouncementDto[];
    totalCount?: number;
}

export interface DynamicAnnouncementDto {
    id?: string;
    collectionId: string;
    type: DynamicAnnouncementType;
    position?: number;
    fileName?: string;
    uploadedBlobName?: string;
    oldType?: DynamicAnnouncementType;
    oldPosition?: number;
    collectionType: AnnouncementType;
}

export enum DynamicAnnouncementType {
    Default = 0,
    Position = 1
}

export const DynamicAnnouncementTypeToLabelMapping: Record<DynamicAnnouncementType, string> = {
    [DynamicAnnouncementType.Default]: "Announcement.DynamicDefault",
    [DynamicAnnouncementType.Position]: "Announcement.DynamicPosition",
};

export class UpdateAnnouncementDto {
    id = '00000000-0000-0000-0000-000000000000';
    fileName: string;
    oldAnnouncementType = AnnouncementType.NoType;
    announcementType: AnnouncementType;
    uploadedBlobName: string;
    mediaFileSource: number;
    text: string;
    voice: string;
    regenerateAudioFile: boolean;
}

export interface AnnouncementInUse {
    announcementId: string;
    announcementName: string;
    queueNames: string[];
    flowNames: string[];
}

export interface MaxNumberOfAnnouncementsDto {
    maxWaitingQueuePlaylist: number;
    maxOnHoldPlaylist: number;
}

export interface DeleteBlobEntity {
    name: string;
}

export interface AudioFileGenerateDto {
    name: string;
    voice: string;
    text: string;
}

export enum AnnouncementType {
    Announcement = 0,
    Close = 1,
    OnHold = 2,
    Open = 3,
    Tone = 4,
    Waiting = 5,
    Ringing = 6,
    NoType = 7,
    DynamicPosition = 8,
    CallbackRegistration = 9,
    DynamicWaitingTime = 10,
    Busy = 11,
    Listen = 12
}

export const AnnouncementTypeToLabelMapping: Record<AnnouncementType, string> = {
    [AnnouncementType.Announcement]: "Announcement.AnnouncementType",
    [AnnouncementType.Close]: "Announcement.CloseType",
    [AnnouncementType.OnHold]: "Announcement.OnHoldType",
    [AnnouncementType.Open]: "Announcement.OpenType",
    [AnnouncementType.Tone]: "Announcement.ToneType",
    [AnnouncementType.Waiting]: "Announcement.WaitingType",
    [AnnouncementType.Ringing]: "Announcement.Ringing",
    [AnnouncementType.NoType]: "Announcement.NoType",
    [AnnouncementType.DynamicPosition]: "Announcement.Dynamic",
    [AnnouncementType.CallbackRegistration]: "Announcement.CallbackRegistration",
    [AnnouncementType.DynamicWaitingTime]: "Announcement.DynamicWaitingTime",
    [AnnouncementType.Busy]: "Announcement.BusyType",
    [AnnouncementType.Listen]: "Announcement.ListenType"
};

export enum MediaFileSource {
    Uploaded = 1,
    Generated = 2
}

export const MediaFileSourceToLabelMapping: Record<MediaFileSource, string> = {
    [MediaFileSource.Uploaded]: "Announcement.UploadSource",
    [MediaFileSource.Generated]: "Announcement.GenerateSource"
};

export interface AddEditMediaFileProps {
    announcement: AnnouncementDto;
    collectionId: string;
    dynamicFile: DynamicAnnouncementDto;
    announcementType?: AnnouncementType
}

export interface DynamicAnnouncementFormElementsProps {
    announcementType?: AnnouncementType
}

export interface AnnouncementFormData {
    name: string;
    mediaFileType: string;
    mediaDynamicType: any;
    mediaDynamicPosition: any;
    mediaFileSource: any;
    text: string;
    languageId: string;
    voice: string;
    test: any;
    submit: any;
}

export interface UploadMediaFileSectionProps {
    isEdit: boolean;
    handleOpenPlayAnnouncementDialog: () => void;
    files: File[];
    uploadedFileName: React.MutableRefObject<any>;
    handleDrop: (newFiles: File[]) => void;
    onUpload: () => void;
    removeUploadedFile: () => void;
}

export interface GenerateMediaFileSectionProps {
    handleGenerateMediaFile: () => void;
    isEdit: boolean;
}

export interface Language {
    id: string,
    name: string
}

export interface Voice {
    id: string,
    name: string,
    languageId: string
}
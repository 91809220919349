import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { UserDto } from "src/types/user";
import { Loader } from "../shared";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from 'yup';
import { UserSkill } from "src/types/skill";
import { SelectListItem, ChannelTypeEnum } from "src/types/shared";
import { HTTPSTATUSCODES, SkillsFieldLimits, SkillsTranslationKeys } from "src/utils/constants";
import Lockr from 'lockr';
import { StorageKeys } from "src/utils/storageKeys";
import ChangesFormActions from "../shared/formChanges/ChangesFormActions";
import { Grid, Checkbox, Typography, FormControlLabel,Dialog, DialogActions, DialogContent, DialogContentText, Button, Tooltip } from "@mui/material";
import { CustomTextField } from "../shared/CustomTextField";
import { MenuRoutes } from "src/types/routes";
import { setRowIdForUserSkills } from "src/utils/skillHelper";
import { skillService } from "src/services/skills";
import { userService } from "src/services/users";
import SkillsSection from "../skills/SkillsSection";
import { Info } from "@mui/icons-material";
import { useStyles } from 'src/theme/styles';

const EditUser: FC = () => {
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const tenantId = Lockr.get(StorageKeys.TenantId) || '';
    const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const { userId, queueType, queueId } = useParams();
    const [user, setUser] = useState<UserDto | null>({} as UserDto);

    const [assignableSkills, setAssignableSkills] = useState<SelectListItem[]>([] as SelectListItem[]);

    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const queueTypeRoute = queueType === ChannelTypeEnum.Voice.toString() ? MenuRoutes.Calls : MenuRoutes.Generic;
    const route = queueId ? `${queueTypeRoute}/${MenuRoutes.Queues}/${queueId}` : MenuRoutes.Users;

    const getUser = async () => {
        if (userId) {
            try {
                const response = await userService.getUserById(userId);

                if (isMountedRef.current && response?.status == HTTPSTATUSCODES.StatusCodeSuccess) {
                    if (response.data.userSkills && response.data.userSkills.length > 0) {
                        setRowIdForUserSkills(response.data.userSkills);
                    }

                    setUser(response.data);
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        getUser();
    }, [userId]);

    const handleCancelForm = (handleReset: (e: any) => void, dirty: boolean) => {
        dirty ? handleReset(null) : navigate(route);
    };

    const getAllSkills = async (): Promise<void> => {
        try {
            const response = await skillService.getAllSkills();

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                setAssignableSkills(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAllSkills();
    }, []);

    const getAssignedSkills = (userSkills: UserSkill[]) => {
        const assignedSkills = userSkills.filter(x => Boolean(x.skillId));

        return assignedSkills.length > 0 ? assignedSkills.map(x => x.skillId) : [];
    };

    const updateUser = async (updatedUser: UserDto): Promise<void> => {
        setIsSubmitting(true);

        try {
            const response = await userService.updateUser(updatedUser);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                enqueueSnackbar(t('Users.UpdateSucceeded'), { variant: 'success' });
                setIsSubmitting(false);
                navigate(route);
            }
        } catch (error) {
            if (error?.response?.status === HTTPSTATUSCODES.StatusCodeForbidden) {
                enqueueSnackbar(t('Users.EntityDoesNotExist'), { variant: 'error' });
            } else {
                enqueueSnackbar(t('Users.UpdateFailed'), { variant: 'error' });
            }

            setIsSubmitting(false);
            console.error(error);
        }
    };

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }

    const openPopupIfNeeded = (values:any) => {
        if(!user){
            return false;
        }

        if(user.hasOperatorRights && !values.hasParkingRight && !values.hasAllInteractionVisibilityRight){
            setDialogMessage("OperatorRemoved");
            setSaveDialogOpen(true);
            return true;
        }
        
        if(!user.hasOperatorRights && (values.hasParkingRight || values.hasAllInteractionVisibilityRight)){
            setDialogMessage("OperatorAdded");
            setSaveDialogOpen(true);
            return true;
        }

        return false;
    }

    const updateUserByValues = ((values: any) =>{
        const editedUser: UserDto = {
            id: userId,
            name: values.name,
            userSkills: values.userSkills,
            hasParkingRight: values.hasParkingRight,
            hasAllInteractionVisibilityRight: values.hasAllInteractionVisibilityRight,
            hasOperatorRights: user.hasOperatorRights,
            tenantId: tenantId
        };
        
        updateUser(editedUser);
    });

    const handleSaveDialogConfirmation = ((values: any) => {
        setSaveDialogOpen(false);
        updateUserByValues(values);
    });
    
    return (
        <>
            <Loader isOpen={!(isDataLoaded || !userId)} isInline={false} />
            <Helmet>
                <title>
                    {t('Users.EditUser')} | ContactCenter4All
                </title>
            </Helmet>
            {(isDataLoaded || !userId) &&
                <Formik
                    initialValues={{
                        name: userId ? user?.name : "",
                        userSkills: userId && user.userSkills ? user.userSkills : [] as UserSkill[],
                        hasParkingRight: userId ? user?.hasParkingRight : false,
                        hasAllInteractionVisibilityRight: userId ? user?.hasAllInteractionVisibilityRight : false
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            userSkills: Yup
                                .array()
                                .of(Yup.object().shape({
                                    skillId: Yup.string().required(t(SkillsTranslationKeys.Required)),
                                    skillLevel: Yup
                                                .number()
                                                .integer(t('Skills.InvalidValue'))
                                                .max(SkillsFieldLimits.SkillLevelMaxValue, `${t('Skills.SkillLevelMaxValueError')} ${SkillsFieldLimits.SkillLevelMaxValue}`)
                                                .min(SkillsFieldLimits.SkillLevelMinValue, `${t('Skills.SkillLevelMinValueError')} ${SkillsFieldLimits.SkillLevelMinValue}`)
                                                .required(t(SkillsTranslationKeys.Required))
                                }))
                        })
                    }
                    onSubmit={(values) => {
                        if(queueId === undefined){
                            const isPopupOpened = openPopupIfNeeded(values);

                            if(isPopupOpened){
                                return;
                            }
                        }

                        updateUserByValues(values);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, handleReset, touched, values, dirty }): JSX.Element => (
                        <form onSubmit={handleSubmit}>
                            <ChangesFormActions isValid={true} isSubmitting={isSubmitting} handleReset={() => handleCancelForm(handleReset, dirty)} isSaveAction={false} />
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <CustomTextField
                                        size="small"
                                        color="info"
                                        fullWidth
                                        label={t('Users.UserName')}
                                        name="name"
                                        value={values.name}
                                        variant="outlined"
                                        data-testid="name"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} />
                                <SkillsSection
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    assignableOptions={assignableSkills}
                                    errors={errors}
                                    touched={touched}
                                    assignedOptions={getAssignedSkills(values.userSkills)}
                                    userId={userId}
                                    optionsTitle={t('Users.Skill')}
                                    optionName="skillId"
                                />
                                 <Grid item xs={5} className={classes.flex}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        {t('Users.OperatorPermissions')}
                                    </Typography>
                                    <Tooltip
                                        title={t(
                                            "Users.PermissionTooltip"
                                        )}
                                    >
                                        <Info color="primary" fontSize="small" className={classes.tooltipMargin}/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            color="primary"
                                            checked={values.hasParkingRight}
                                            name="hasParkingRight"
                                            onChange={handleChange}
                                            disabled={queueId !== undefined}
                                        />
                                        }
                                        label={
                                        <Typography color="textSecondary">
                                            {t("Users.CanPark")}
                                        </Typography>
                                        }
                                        data-testid="hasParkingRight"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            color="primary"
                                            checked={values.hasAllInteractionVisibilityRight}
                                            name="hasAllInteractionVisibilityRight"
                                            onChange={handleChange}
                                            disabled={queueId !== undefined}
                                        />
                                        }
                                        label={
                                        <Typography color="textSecondary">
                                            {t("Users.CanViewAllInteractions")}
                                        </Typography>
                                        }
                                        data-testid="hasAllInteractionVisibilityRight"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Dialog
                                open={isSaveDialogOpen}
                                onClose={handleSaveDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t(`Users.${dialogMessage}`)}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={() => handleSaveDialogConfirmation(values)}
                                    >
                                        {t('UserList.SaveDialog.Confirmation')}
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        onClick={handleSaveDialogClose}
                                    >
                                        {t('UserList.SaveDialog.Decline')}
                                    </Button>
                                </DialogActions>
                            </Dialog>                
                        </form>
                    )}
                </Formik>
            }       
        </>
    );
};

export default EditUser;
import "react-perfect-scrollbar/dist/css/styles.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import reportWebVitals from "./reportWebVitals";
// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { SnackbarProvider } from "notistack";
import { SNACKBAR } from "./utils/constants";
import ErrorBoundary from "./utils/errorBoundary";

(async () => {

const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
const container = document.getElementById("root");
const root = createRoot(container)
root.render(
  <ErrorBoundary>
    <StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SettingsProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <SnackbarProvider
                      preventDuplicate
                      maxSnack={3} 
                      autoHideDuration={SNACKBAR.SnackBarAutoHideDuration} 
                      anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      disableWindowBlurListener
                    >
                      <App pca={msalInstance} />
                    </SnackbarProvider>
                  </AuthProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </HelmetProvider>
    </StrictMode>
  </ErrorBoundary>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

})();
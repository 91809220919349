import type { FC } from 'react';
import Lockr from 'lockr';
import { StorageKeys } from '../../utils/storageKeys';

var dayjs = require("dayjs")
var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone")

interface FormattedDateProps {
    dateValue?: string;
    isRecurrent?: boolean;
    ignoreTimezone?: boolean;
    isValueUTC?: boolean;
}

const FormattedDate: FC<FormattedDateProps> = (props) => {
    if (props.ignoreTimezone){
        return (
            <>
                {dayjs(props.dateValue).format(props.isRecurrent ? 'DD/MM HH:mm' : 'DD/MM/YYYY HH:mm')}
            </>
        );
    }

    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault()

    const appTimezone = Lockr.get<string>(StorageKeys.Timezone) || Intl.DateTimeFormat().resolvedOptions().timeZone;

    let dayjsDate: any;
    if(props.isValueUTC) {
        dayjsDate = dayjs.utc(props.dateValue);
    }
    else {
        dayjsDate = dayjs(props.dateValue).utc()
    }

    return (
        <>
            {dayjsDate.tz(appTimezone).format(props.isRecurrent ? 'DD/MM HH:mm' : 'DD/MM/YYYY HH:mm')}
        </>
    );
};

export default FormattedDate;
import { AxiosResponse } from "axios";
import { EmailEntryPointService } from "./emailEntryPointService";
import { TypeEnum } from "src/types/textEntryPoints";

export interface ApiEmailEntryPoints {
    getEmailEntryPoints(urlParams: string): Promise<AxiosResponse>;
    deleteEmailEntryPoints(emailEntryPointIds: string[]): Promise<AxiosResponse>;
    getAssignToData(type: TypeEnum): Promise<AxiosResponse>;
    getAllEmailEntryPoints(): Promise<AxiosResponse>;
}

export const emailEntryPointService = new EmailEntryPointService();
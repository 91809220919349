import { UserSkill } from "src/types/skill";

export const getSkillsTooltipMessage = (userId: string, skills: UserSkill[]) => {
    if (!Boolean(skills)) {
        return '';
    }
    
    const userSkills = skills.filter(x => x.userId === userId);

    return userSkills.map(x => `${x.skillName} (${x.skillLevel})`).join(', ');
};

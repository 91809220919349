import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SharedGridPage from './SharedGridPage';
import { useParams } from 'react-router';
import { AddEditTextEntryPoint } from 'src/components/textEntryPoints';

const ManageTextEntryPoint: FC = () => {
    const { t } = useTranslation();
    const { entryPointId } = useParams();

    return (
        <SharedGridPage
            title={entryPointId ? t('TextEntryPoint.EditTextEntryPoint') : t('TextEntryPoint.AddTextEntryPoint')}
            info={t('TextEntryPoint.AddEditInfo')}
            subComponent={<AddEditTextEntryPoint />}
        />
    );
};

export default ManageTextEntryPoint;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SharedGridPage from "./SharedGridPage";
import AddEditSkill from "src/components/skills/AddEditSkill";


const ManageSkill: FC = () => {
    const { t } = useTranslation();
    const { skillId } = useParams();

    return (
        <SharedGridPage
            title={skillId ? t('Skills.EditSkill') : t('Skills.AddSkill')}
            subComponent={<AddEditSkill />}
        />
    );
};

export default ManageSkill;
import axios, { AxiosResponse } from "axios";
import { InvokeServiceArgs } from "./interfaces";
import Lockr from 'lockr';
import { StorageKeys } from '../utils/storageKeys';
import { HttpMethods } from "./httpMethods";
import { apiConfig } from "src/config";
import { AuthApiRoutes } from "src/utils/constants";
import { setupInterceptorsTo } from "./axios-interceptor";

export class ServiceCall {
    private readonly api;
    private static readonly allowedAnonymousRequests = [AuthApiRoutes.VerifyUser, AuthApiRoutes.Tenants, AuthApiRoutes.Deployments];
    constructor() {
        this.api = setupInterceptorsTo(
            axios.create({
                baseURL: apiConfig.fullUrl,
                headers: {
                    'Content-Type': 'application/json',
                    '__tenant': Lockr.get(StorageKeys.TenantId) || ''
                }
            })
        );
    }

    private static isAllowedAnonymousOperation(operation: string): boolean {
        return ServiceCall.allowedAnonymousRequests.includes(operation);
    }

    async invokeService(args: InvokeServiceArgs): Promise<AxiosResponse> {
        let response: Promise<AxiosResponse>;

        const tenantId = Lockr.get(StorageKeys.TenantId) || '';

        if (tenantId === '' && !ServiceCall.isAllowedAnonymousOperation(args.route)) {
            Lockr.flush();
            window.location.href = '/';
            response = null;
        }
        else {
            let url = `/${args.controller}/${args.route}`;
            switch (args.httpMethod) {
                case HttpMethods.Get:
                    if (args.urlParams) {
                        url = `${url}${args.urlParams}`;
                    }
                    response = this.api.get(url);
                    break;
                case HttpMethods.Post:
                    response = this.api.post(url, args.data);
                    break;
                default:
                    response = null;
            }
        }
        return response;
    }
}

export const serviceCall = new ServiceCall();

import { ApiUsers } from ".";
import { InvokeServiceArgs } from "../interfaces";
import { serviceCall } from "../service-call";
import { AxiosResponse } from 'axios';
import { HttpMethods } from "../httpMethods";
import { UserDto, UserRightsChanged } from "../../types/user";
import { Controllers, GeneralRoutes, UserApiRoutes } from "../../utils/constants";

export class UserService implements ApiUsers {
    async getUsers(urlParams: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, UserApiRoutes.Users, urlParams);
    }

    async updateUsers(data: UserRightsChanged[]): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.User, UserApiRoutes.Users, null, data);
    }

    async getActiveUsers(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, UserApiRoutes.ActiveUsers);
    }

    async getUserById(userId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.User, '', `${userId}`);
    }

    async updateUser(user: UserDto): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.User, GeneralRoutes.Update, null, user);
    }

    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };

        return serviceCall.invokeService(args);
    }
}
import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "./i18n";
import RTL from "./components/RTL";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { createCurrentTheme } from "./theme";
import { useTranslation } from "react-i18next";
import { StorageKeys } from './utils/storageKeys';
import Lockr from 'lockr';
import { DefaultSettings } from "./utils/constants";
// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

type AppProps = {
  pca: IPublicClientApplication;
};
function App({ pca }: AppProps) {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const { i18n } = useTranslation();
  useScrollReset();

  useEffect(() => {
    i18n.changeLanguage(Lockr.get<string>(StorageKeys.Language, DefaultSettings.Language));

    const $style = document.createElement("style");
    document.head.appendChild($style);
    $style.innerHTML = `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
}`;

  }, []);

  const theme = createCurrentTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return <ThemeProvider theme={theme}><RTL direction={settings.direction}>
    <MsalProvider instance={pca}>
      {auth.isInitialized ? content : <SplashScreen />}</MsalProvider>
  </RTL></ThemeProvider>;
}

export default App;

import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, } from '@mui/material';

interface DeleteButtonProps {
    handleDelete: () => Promise<void>;
    isDisabled?: boolean;
}

const DeleteButton: FC<DeleteButtonProps> = (props) => {
    const { handleDelete, isDisabled } = props;
    const { t } = typeof jest !== 'undefined' ? { t: s => s } : useTranslation();

    return (
        <Button color="primary" sx={{ ml: 2 }} variant="outlined" onClick={handleDelete} disabled={isDisabled}>
            {t("Shared.Delete")}
        </Button>
    );
};

export default DeleteButton;
